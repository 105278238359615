import Service from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment';
import { trackEvent } from '../utils/matomo-events';
export default class ImboxService extends Service {
  @tracked isSupportDrawerOpen = false;
  @tracked hasInitialized = false;

  timeOpen = null;

  load(user, school) {
    if (this.hasInitialized || config.environment === 'test') return;

    const role = user.role;

    if (!role || role === 'student') return;

    window._sid = '2417';
    window._imbox = window._imbox || [];
    window._imboxSettings = {
      cookieDomain: config.cookieDomain,
    };

    function handleToggle(data) {
      if (data.isOpen) {
        this.timeOpen = new Date();
        trackEvent({ category: 'Support', action: 'Klickar på support', name: location.href });
      } else {
        const elapsed = (new Date() - this.timeOpen) / 1000; // get elapsed time in seconds
        trackEvent({ category: 'Support', action: 'Stänger support', name: location.href, value: elapsed });
      }
      this.isSupportDrawerOpen = data.isOpen;

      this._toggleImboxContainer(data.isOpen);

      if (data.isOpen) {
        document.documentElement.dataset['supportContainerMoved'] = 'true';
      } else {
        delete document.documentElement.dataset['supportContainerMoved'];
      }
    }

    const userInfo = {
      id: user.id,
      name: `${user.firstname}${user.lastname ? ' ' + user.lastname : ''}`,
      role: user.role,
      username: user.username,
      email: user.email,
    };

    let translatedRole = userInfo.role;

    // Hardcoded to swedish, but it´s just for Imbox
    switch (userInfo.role.toLowerCase()) {
      case 'private':
        translatedRole = 'Privat';
        break;
      case 'student':
        translatedRole = 'Elev';
        break;
      case 'teacher':
        translatedRole = 'Lärare';
        break;
    }

    let card = `${userInfo.name}<br />
      <a href="${config.adminUrl}/users/${userInfo.id}">${userInfo.username}</a><br />
      ${translatedRole}`;

    if (school) {
      card += `<br /><a href="${config.adminUrl}/schools/${school.get('id')}">${school.get('name')}</a>`;
    }

    card += '<br/> Via: nokportalen.se';

    // Settings, https://imbox.se/docs/online/dokumentation-v2/
    window._imbox.push(['hideLauncher']);
    window._imbox.push(['hideWidget']);

    // User info
    window._imbox.push([
      'changeName',
      `${translatedRole}: ${userInfo.name} - ${userInfo.username}`,
    ]);

    window._imbox.push(['insertCard', userInfo.id, card]);

    const formValues = [
      { id: 'a5c0acf7-1623-4529-98e2-e0def15e718d', value: userInfo.name, formId: 5726 },
      { id: 'bc4b69a5-5233-483c-85fe-de22687cd2a0', value: userInfo.role.toLowerCase() === 'private' ? 'Privat' : 'Skola & Organisation', formId: 5726 },
    ];

    // Only populate usernames that can be email addresses and ignore Hermods users since their ones are made up
    if (/@{1}/i.test(user.username) && user.provider !== "hermods") {
      formValues.push({ id: '40c5d8ca-c41f-4a01-aa0a-372b537a6ad2', value: userInfo.username, formId: 5726 });
    }

    if (school) {
      formValues.push({ id: '15588583-fdf9-4265-be7e-350d10374db2', value: school.get('name'), formId: 5726 });
    }

    // User info
    window._imbox.push([
      'setFormValue',
      formValues
    ]);

    // Events
    window._imbox.push(['onToggle', handleToggle.bind(this)]);

    const se = document.createElement('script');

    se.type = 'text/javascript';
    se.async = true;
    se.src = 'https://files.imbox.io/app/dist/initWidget.js';

    se.onload = () => {
      const observer = new MutationObserver((records, observer) => {
        let element = null;

        for (let record of records) {
          const addedNodes = record.addedNodes;

          if (addedNodes) {
            for (let node of addedNodes) {
              if (node.id === "imbox-container") {
                element = node;
                break;
              }
            }
          }
        }

        if (element) {
          this._toggleImboxContainer(false);
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
      });
    }

    document.head.appendChild(se);

    this.hasInitialized = true;
  }

  _toggleImboxContainer(visible) {
    const container = document.getElementById("imbox-container");

    if (container) {
      container.style.display = visible ? null : "none";

      if (visible) {
        window._imbox.push(['hideLauncher']);
      }
    }
  }

  @action
  onClick() {
    if (window._imbox && typeof window._imbox.push === 'function') {
      window._imbox.push(['toggle']);
    }
  }
}
